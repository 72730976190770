var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
(function () {
  // Public sightglass interface.
  function sightglass(obj, keypath, callback, options) {
    return new Observer(obj, keypath, callback, options);
  } // Batteries not included.


  sightglass.adapters = {}; // Constructs a new keypath observer and kicks things off.

  function Observer(obj, keypath, callback, options) {
    (this || _global).options = options || {};
    (this || _global).options.adapters = (this || _global).options.adapters || {};
    (this || _global).obj = obj;
    (this || _global).keypath = keypath;
    (this || _global).callback = callback;
    (this || _global).objectPath = [];
    (this || _global).update = (this || _global).update.bind(this || _global);
    this.parse();

    if (isObject((this || _global).target = this.realize())) {
      this.set(true, (this || _global).key, (this || _global).target, (this || _global).callback);
    }
  } // Tokenizes the provided keypath string into interface + path tokens for the
  // observer to work with.


  Observer.tokenize = function (keypath, interfaces, root) {
    var tokens = [];
    var current = {
      i: root,
      path: ""
    };
    var index, chr;

    for (index = 0; index < keypath.length; index++) {
      chr = keypath.charAt(index);

      if (!!~interfaces.indexOf(chr)) {
        tokens.push(current);
        current = {
          i: chr,
          path: ""
        };
      } else {
        current.path += chr;
      }
    }

    tokens.push(current);
    return tokens;
  }; // Parses the keypath using the interfaces defined on the view. Sets variables
  // for the tokenized keypath as well as the end key.


  Observer.prototype.parse = function () {
    var interfaces = this.interfaces();
    var root, path;

    if (!interfaces.length) {
      error("Must define at least one adapter interface.");
    }

    if (!!~interfaces.indexOf((this || _global).keypath[0])) {
      root = (this || _global).keypath[0];
      path = (this || _global).keypath.substr(1);
    } else {
      if (typeof (root = (this || _global).options.root || sightglass.root) === "undefined") {
        error("Must define a default root adapter.");
      }

      path = (this || _global).keypath;
    }

    (this || _global).tokens = Observer.tokenize(path, interfaces, root);
    (this || _global).key = (this || _global).tokens.pop();
  }; // Realizes the full keypath, attaching observers for every key and correcting
  // old observers to any changed objects in the keypath.


  Observer.prototype.realize = function () {
    var current = (this || _global).obj;
    var unreached = false;
    var prev;

    (this || _global).tokens.forEach(function (token, index) {
      if (isObject(current)) {
        if (typeof (this || _global).objectPath[index] !== "undefined") {
          if (current !== (prev = (this || _global).objectPath[index])) {
            this.set(false, token, prev, (this || _global).update);
            this.set(true, token, current, (this || _global).update);
            (this || _global).objectPath[index] = current;
          }
        } else {
          this.set(true, token, current, (this || _global).update);
          (this || _global).objectPath[index] = current;
        }

        current = this.get(token, current);
      } else {
        if (unreached === false) {
          unreached = index;
        }

        if (prev = (this || _global).objectPath[index]) {
          this.set(false, token, prev, (this || _global).update);
        }
      }
    }, this || _global);

    if (unreached !== false) {
      (this || _global).objectPath.splice(unreached);
    }

    return current;
  }; // Updates the keypath. This is called when any intermediary key is changed.


  Observer.prototype.update = function () {
    var next, oldValue;

    if ((next = this.realize()) !== (this || _global).target) {
      if (isObject((this || _global).target)) {
        this.set(false, (this || _global).key, (this || _global).target, (this || _global).callback);
      }

      if (isObject(next)) {
        this.set(true, (this || _global).key, next, (this || _global).callback);
      }

      oldValue = this.value();
      (this || _global).target = next; // Always call callback if value is a function. If not a function, call callback only if value changed

      if (this.value() instanceof Function || this.value() !== oldValue) this.callback();
    }
  }; // Reads the current end value of the observed keypath. Returns undefined if
  // the full keypath is unreachable.


  Observer.prototype.value = function () {
    if (isObject((this || _global).target)) {
      return this.get((this || _global).key, (this || _global).target);
    }
  }; // Sets the current end value of the observed keypath. Calling setValue when
  // the full keypath is unreachable is a no-op.


  Observer.prototype.setValue = function (value) {
    if (isObject((this || _global).target)) {
      this.adapter((this || _global).key).set((this || _global).target, (this || _global).key.path, value);
    }
  }; // Gets the provided key on an object.


  Observer.prototype.get = function (key, obj) {
    return this.adapter(key).get(obj, key.path);
  }; // Observes or unobserves a callback on the object using the provided key.


  Observer.prototype.set = function (active, key, obj, callback) {
    var action = active ? "observe" : "unobserve";
    this.adapter(key)[action](obj, key.path, callback);
  }; // Returns an array of all unique adapter interfaces available.


  Observer.prototype.interfaces = function () {
    var interfaces = Object.keys((this || _global).options.adapters);
    Object.keys(sightglass.adapters).forEach(function (i) {
      if (!~interfaces.indexOf(i)) {
        interfaces.push(i);
      }
    });
    return interfaces;
  }; // Convenience function to grab the adapter for a specific key.


  Observer.prototype.adapter = function (key) {
    return (this || _global).options.adapters[key.i] || sightglass.adapters[key.i];
  }; // Unobserves the entire keypath.


  Observer.prototype.unobserve = function () {
    var obj;

    (this || _global).tokens.forEach(function (token, index) {
      if (obj = (this || _global).objectPath[index]) {
        this.set(false, token, obj, (this || _global).update);
      }
    }, this || _global);

    if (isObject((this || _global).target)) {
      this.set(false, (this || _global).key, (this || _global).target, (this || _global).callback);
    }
  }; // Check if a value is an object than can be observed.


  function isObject(obj) {
    return typeof obj === "object" && obj !== null;
  } // Error thrower.


  function error(message) {
    throw new Error("[sightglass] " + message);
  } // Export module for Node and the browser.


  if (exports) {
    exports = sightglass;
  } else (this || _global).sightglass = sightglass;
}).call(exports);
export default exports;